import React, { FC, useCallback, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { homeCategories } from 'constants/home-categories';
import { filtersValuesSearchParamsClassifier } from 'constants/job';
import useTranslate from 'utils/i18n/useTranslate';
import { useFiltersValues } from 'utils/swr/hooks/useFiltersValues';
import { useUser } from 'utils/swr/hooks/useUser';
import { useAppDispatch, useHomePageJobsUrl } from 'hooks/*';
import { openCreateAccountModal } from 'store/reducers/uiReducer';
import {
  homeCategoriesStyles,
  SignUpLink,
  ViewAllJobsLink,
} from 'components/home/home-categories/home-categories.styles';
import { HomeCategoryItem } from 'components/home/home-category-item';

export const HomeCategories: FC = () => {
  const { t } = useTranslate('home');
  const dispatch = useAppDispatch();
  const { jobsUrl } = useHomePageJobsUrl();
  const {
    user: { isAuthed },
  } = useUser();

  const handleOpenCreateAccountModal = (
    event: React.MouseEvent<HTMLAnchorElement>,
  ) => {
    event.preventDefault();
    dispatch(openCreateAccountModal());
  };
  const { filtersQueryPath, saveJobCategories } = useFiltersValues();
  const filtersWithoutCategory = useMemo(() => {
    filtersQueryPath.delete(
      filtersValuesSearchParamsClassifier.job_occupations,
    );
    return filtersQueryPath;
  }, [filtersQueryPath]);

  const resetCategories = useCallback(() => {
    saveJobCategories([]);
  }, [saveJobCategories]);

  return (
    <Box component="section" sx={homeCategoriesStyles.wrapper}>
      <Box
        data-current="Tab 1"
        data-easing="ease"
        data-duration-in="300"
        data-duration-out="100"
        className="w-tabs"
      >
        <Typography component="h4" sx={homeCategoriesStyles.title}>
          {t('marketing.popularCategories')}
        </Typography>

        <Box sx={homeCategoriesStyles.list}>
          {homeCategories.map((item) => (
            <HomeCategoryItem
              id={item.id}
              key={item.id}
              title={item.title}
              iconClass={item.icon}
              link={`${item.link}&${filtersWithoutCategory.toString()}`}
            />
          ))}
        </Box>
      </Box>
      <Box sx={homeCategoriesStyles.navigationWrapper}>
        {!isAuthed && (
          <Box>
            <SignUpLink
              href={{
                pathname: '/',
                query: { 'create-account': true },
              }}
              onClick={handleOpenCreateAccountModal}
            >
              {t('marketing.createAccount')}
            </SignUpLink>
          </Box>
        )}
        <Box>
          <ViewAllJobsLink
            href={`${jobsUrl}`}
            onClick={resetCategories}
            aria-label={t('marketing.viewAllJobs')}
          >
            {t('marketing.viewAllJobs')}
          </ViewAllJobsLink>
        </Box>
      </Box>
    </Box>
  );
};
