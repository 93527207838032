import theme from 'theme/theme';

export const deepLinksModalStyles = {
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
  },
  content: {
    paddingBottom: theme.spacing(3),
  },
  iconButton: {
    height: 40,
    width: 40,
  },
  root: {
    [theme.breakpoints.down('lg')]: {
      alignItems: 'flex-end',
    },
    [theme.breakpoints.down('lg')]: {
      '& .MuiDialog-paper': {
        margin: 0,
        maxWidth: '100%',
        width: '100%',
        borderTopLeftRadius: '30px',
        borderTopRightRadius: '30px',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
        marginTop: 'auto',
      },
    },
  },
};
