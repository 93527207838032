import { getMessaging, onMessage } from 'firebase/messaging';
import { FC, useEffect, useRef, useState } from 'react';
import { ClickAwayListener, Grow, Paper, Popper } from '@mui/material';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBell } from '@fortawesome/pro-solid-svg-icons';
import firebaseApp from 'utils/firebase';
import { firebaseCloudMessaging } from 'utils/helpers/webPush';
import { useNotifications } from 'utils/swr/hooks/useNotifications';
import { HeaderIconButton } from 'components/layout/Header/header-icon-button';
import { NotificationMenu } from '../notifications-menu';
import { INotifications } from './notifications.interface';

export const Notifications: FC<INotifications> = ({ sx }) => {
  const { notifications, mutate, readNotifications } = useNotifications();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  useEffect(() => {
    firebaseCloudMessaging.init();
    const messaging = getMessaging(firebaseApp);
    const unsubscribe = onMessage(messaging, () => {
      mutate();
    });
    return () => {
      unsubscribe();
    };
  }, [mutate]);

  useEffect(() => {
    if (open) {
      readNotifications();
    }
  }, [open, readNotifications]);

  const handleToggle = () => setOpen(!open);

  const handleClose = () => setOpen(false);

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  return (
    <>
      <HeaderIconButton
        sx={sx}
        icon={faBell as IconProp}
        itemsNumber={
          notifications?.filter(({ is_read }) => is_read === 0).length
        }
        ref={anchorRef}
        onClick={handleToggle}
      />

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        style={{ zIndex: 5 }}
      >
        {({ TransitionProps }) => (
          // eslint-disable-next-line
          <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <NotificationMenu
                  notifications={notifications}
                  onListKeyDown={handleListKeyDown}
                  open={open}
                />
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default Notifications;
