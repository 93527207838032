import React, { forwardRef } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import palette from 'theme/palette';
import { IHeaderIconButton } from './header-icon-button.interface';
import { headerIconButtonStyles } from './header-icon-button.styles';

export const HeaderIconButton = forwardRef<
  HTMLAnchorElement,
  IHeaderIconButton
>(
  (
    { icon, iconColor = palette.darkGrey.main, itemsNumber, sx, href, onClick },
    ref,
  ) => (
    <IconButton
      ref={ref}
      href={href}
      onClick={onClick}
      sx={{ ...sx, ...headerIconButtonStyles.iconButton }}
    >
      <FontAwesomeIcon
        icon={icon}
        color={iconColor}
        style={headerIconButtonStyles.iconStyle}
      />
      {!!itemsNumber && (
        <Box sx={headerIconButtonStyles.unreadContainer}>
          <Typography
            variant="caption"
            color="textSecondary"
            sx={headerIconButtonStyles.unreadText}
            component="span"
          >
            {itemsNumber <= 9 ? itemsNumber : '9+'}
          </Typography>
        </Box>
      )}
    </IconButton>
  ),
);

HeaderIconButton.displayName = 'HeaderIconButton';

export default HeaderIconButton;
