import background from 'public/images/home-why-background.webp';
import ninjaIcon2 from 'public/images/icons/ninja-icon-1.svg';
import ninjaIcon1 from 'public/images/icons/ninja-icon-3.svg';
import theme from 'theme/theme';

export const homeTestimonialsStyles = {
  scrollable: {
    width: '100%',
    marginBottom: '125px',
    '&:hover': {
      overflow: 'auto',
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
  },
  sliderWrapper: {
    backgroundColor: '#06003D',
    flexFlow: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    paddingTop: '40px',
    paddingBottom: '50px',
    display: 'flex',
    position: 'relative',
  },
  animation: {
    position: 'absolute',
    top: '-30px',
    width: '100px',
    height: '100px',
    bottom: 0,
    left: '40%',
    right: 0,
    transform: 'rotate(270deg)',
    [theme.breakpoints.up('sm')]: {
      left: '45%',
    },
  },
  scrollableContent: {
    gridColumnGap: '10px',
    gridRowGap: '10px',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    display: 'flex',
  },
  uiWrapper: {
    paddingTop: '140px',
    paddingBottom: '20px',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '60px',
    },
    [theme.breakpoints.up('md')]: {
      paddingBottom: '140px',
    },
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FFF',
    fontSize: '35px',
    fontWeight: 600,
    flexWrap: 'wrap',
    gap: '5px',
    zIndex: 10,
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.up('sm')]: {
      fontSize: '45px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '65px',
    },
  },
  titleGradient: {
    background: 'linear-gradient(99deg, #150090 7.57%, #FF3030 41.75%)',
    backgroundClip: 'text',
    fontSize: '35px',
    fontWeight: 700,
    color: 'transparent',
    [theme.breakpoints.up('sm')]: {
      fontSize: '45px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '65px',
    },
  },
  wrapper: {
    backgroundColor: '#040028',
    backgroundImage: `url(${background.src})`,
    flexFlow: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    display: 'flex',
    position: 'relative',
  },
  subtitle: {
    color: '#FFF',
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 300,
    zIndex: 10,
    maxWidth: '261px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '25px',
      maxWidth: '400px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '30px',
      maxWidth: '600px',
    },
  },
  subtitleGradient: {
    background: 'linear-gradient(90deg, #EA4335 72.23%, #7B6FDC 92.26%)',
    backgroundClip: 'text',
    color: 'transparent',
    fontWeight: 400,
    position: 'relative',
    '&:after': {
      content: '""',
      width: '100%',
      height: '100%',
      backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='93' height='40' viewBox='0 0 93 40' fill='none'%3E%3Cpath d='M52.1188 1.1641C58.587 4.14708 65.036 8.03377 71.2601 12.1828C74.5293 14.3621 78.1392 16.327 78.8707 21.2403C80.2803 30.7078 67.1509 33.4711 62.579 34.9569C54.3236 37.6399 45.6101 38.6775 36.7137 38.6604C27.1452 38.6419 16.6599 37.8368 7.0589 32.1419C1.9096 29.0875 -0.0460269 23.7357 0.815581 17.3711C1.79702 10.1213 5.51587 7.93446 10.6645 5.99834C25.3246 0.485433 42.8905 2.32082 59.0168 5.21269C67.4776 6.72995 81.3687 8.73476 87.9331 19.3705C90.9625 24.2787 91.85 29.3559 92.1903 34.8463' stroke='%23FF3030' stroke-linecap='round'/%3E%3C/svg%3E")`,
      top: '0',
      left: '0',
      position: 'absolute',
      display: 'inline-block',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: '90%',
    },
  },
  topWrapper: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    paddingBottom: '100px',
    [theme.breakpoints.up('md')]: {
      paddingBottom: '50px',
    },
    [theme.breakpoints.up('md')]: {
      paddingBottom: '0',
    },
  },

  popupQuotes3: {
    position: 'absolute',
    top: '0',
    left: '10%',
    bottom: '0',
    transform: 'rotate(-10deg)',
    maxWidth: 'calc(100vw - 20%)',
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      maxWidth: '260px',
    },
    [theme.breakpoints.up(414)]: {
      maxWidth: '280px',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '370px',
      left: '10%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '370px',
      left: '15%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '400px',
      left: '0',
    },
  },
  popupQuotes2: {
    position: 'absolute',
    top: null,
    bottom: '5%',
    left: '-5%',
    transform: 'rotate(15deg)',
    maxWidth: '190px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '250px',
      left: '-10%',
    },
    [theme.breakpoints.up('md')]: {
      bottom: '10%',
      left: '-15%',
      maxWidth: '290px',
    },
  },
  ninjaIcon2: {
    background: `url(${ninjaIcon1.src})`,
  },
  ninjaIcon3: {
    background: `url(${ninjaIcon2.src})`,
  },
  reviewButton: {
    display: 'flex',
    padding: '15px 35px',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '87.841px',
    background: 'linear-gradient(268deg, #FF3030 -7.36%, #0E008A 162.38%)',
    color: '#FFF',
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 600,
  },
};
