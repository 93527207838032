import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { ISalaryLabelFormat } from './salary-label-format.interface';
import useSliderLabelStyles from './salary-label-format.styles';

const transformNumber = (number: number, separator: string) =>
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);

export const SalaryLabelFormat: FC<ISalaryLabelFormat> = ({
  value = 0,
  currency = '',
  translations: { month = 'mo', year = 'yr' },
}) => {
  const classes = useSliderLabelStyles();

  return (
    <Box className={classes.tooltipTextContainer}>
      {/* Per month */}
      <Typography className={classes.primaryText}>
        {`${transformNumber(value, ' ')}+ ${currency}/${month}`}
      </Typography>

      {/* Per year */}
      <Typography className={classes.secondaryText}>
        {`${transformNumber(value * 12, ',')}+ ${currency}/${year}`}
      </Typography>
    </Box>
  );
};

export default SalaryLabelFormat;
