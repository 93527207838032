import Link from 'next/link';
import { styled } from '@mui/styles';
import palette from 'theme/palette';
import theme from 'theme/theme';

export const ListItemLink = styled(Link)({
  display: 'flex',
  paddingTop: 0,
  paddingBottom: 0,
  paddingRight: '16px',
  paddingLeft: '16px',
  color: '#fff',
  textDecoration: 'none',
  '&:hover': {
    color: palette.lighterGrey.main,
  },
});

export const footerStyles = {
  footerWrapper: {
    background: '#201D39',
    py: '25px',
    color: '#FFFFFF',
  },
  logoPart: {
    display: 'flex',
    flexDirection: 'column',
  },
  description: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
  },
  fund: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    gap: '30px',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-around',
      marginTop: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  divider: {
    borderColor: '#7B6FDC',
    marginTop: '30px',
    marginBottom: '15px',
  },
  list: {
    display: 'flex !important',
    justifyContent: 'space-between !important',
    flexDirection: 'row !important',
    flexWrap: 'wrap !important',
    gap: 2,
    padding: 0,
    [theme.breakpoints.down('md')]: {
      gap: 1,
    },
    [theme.breakpoints.down('sm')]: {
      '& span': {
        fontSize: '14px !important',
      },
    },
  },
  listSubheader: {
    fontSize: '1rem',
    fontWeight: '400',
    backgroundColor: 'inherit',
    color: '#7B6FDC',
  },
  listItemSubheader: {
    backgroundColor: 'transparent',
    color: 'inherit',
  },
  listItem: {
    py: 0,
    '&:hover': {
      color: palette.lighterGrey.main,
    },
  },
  copyright: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem 0',
    color: '#fff',
    backgroundColor: '#2D294E',
  },
};
