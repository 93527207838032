import { FC, ReactElement } from 'react';
import {
  Checkbox as MuiCheckbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  SxProps,
  Typography,
} from '@mui/material';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSquare } from '@fortawesome/pro-light-svg-icons';
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ICheckbox {
  field: {
    name: string;
    onChange: () => void;
    checked: boolean;
  };
  meta?: {
    error?: string;
    touched?: boolean;
  };
  label: string | ReactElement;
  className?: string;
  labelClickDisabled?: boolean;
  sx?: SxProps;
}

export const Checkbox: FC<ICheckbox> = ({
  field: { name, onChange, checked },
  label,
  meta: { error, touched } = { error: '', touched: false },
  className,
  labelClickDisabled,
  sx,
  ...props
}) => (
  <FormControl
    error={!!error && touched}
    {...props}
    className={className}
    sx={{
      marginBottom: '8px',
      ...(sx && sx),
    }}
  >
    <FormGroup row>
      <FormControlLabel
        style={labelClickDisabled ? { pointerEvents: 'none' } : {}}
        control={
          <MuiCheckbox
            color="secondary"
            checked={checked}
            name={name}
            size="medium"
            onChange={onChange}
            checkedIcon={
              <FontAwesomeIcon icon={faCheckSquare as IconProp} size="lg" />
            }
            icon={
              <FontAwesomeIcon
                icon={faSquare as IconProp}
                size="lg"
                color={!!error && touched ? '#FF6067' : ''}
              />
            }
          />
        }
        label={
          <Typography
            variant="body2"
            sx={{
              fontSize: '0.875rem',
            }}
          >
            {label}
          </Typography>
        }
        onChange={onChange}
      />
    </FormGroup>
  </FormControl>
);

export default Checkbox;
