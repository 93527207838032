import NextScript from 'next/script';
import {
  attributesToProps,
  DOMNode,
  Element,
  HTMLReactParserOptions,
} from 'html-react-parser';

const excludedScriptContents = ['w-mod-'];
const replaceScriptToNextScript = (node: Element) => {
  const { attribs } = node;

  const scriptContent = (node.children[0] as any)?.data;
  // avoid setting webflow script which broke Next.js styles
  if (
    scriptContent &&
    excludedScriptContents.some((content) => scriptContent.includes(content))
  ) {
    return null;
  }
  // if (index < 14) {
  const props = attributesToProps(attribs, 'script');
  return <NextScript {...props} />;
  // }
  // return null;
};
const excludedScriptTypes = ['style'];
export const replaceHead = (node: DOMNode) => {
  if (node instanceof Element && node.attribs) {
    if (node.name === 'img') {
      return <></>;
    }

    if (excludedScriptTypes.includes(node.name)) {
      return null;
    }

    if (node.name === 'link') {
      const props = attributesToProps(node.attribs, 'script');
      if (props?.rel === 'stylesheet') {
        return null;
      }
    }
    if (node.name === 'noscript') {
      return <></>;
    }
    if (node.name === 'script') {
      return replaceScriptToNextScript(node);
    }
  }
};

export const headParseOptions: HTMLReactParserOptions = {
  replace: replaceHead,
};
