import { ISWRFiltersValuesDto } from 'types/*';
import { IFiltersValuesDto } from '@nploy/ui-infrastructure';
import { filtersValuesSearchParamsClassifier } from 'constants/job';
import {
  employmentLevelsToSearchParams,
  fromWorkModelToSearchParams,
  jobsCategoriesShort,
  jobsCategoriseSearchParams,
} from 'utils/helpers';
import { workExperienceYearsToValues } from 'utils/work-experience-years-to-values';

export const filtersValuesToSearchParams = (
  filtersValues: IFiltersValuesDto,
): URLSearchParams => {
  const searchParams = new URLSearchParams();

  if (filtersValues.salary_min) {
    searchParams.set(
      filtersValuesSearchParamsClassifier.salary_min,
      filtersValues.salary_min.toString(),
    );
  }
  if (filtersValues.country_id) {
    searchParams.set(
      filtersValuesSearchParamsClassifier.country_id,
      filtersValues.country_id,
    );
  }
  if (filtersValues.cities?.length) {
    filtersValues.cities.forEach((city) => {
      searchParams.append(
        filtersValuesSearchParamsClassifier.cities,
        city.id.toString(),
      );
    });
  }

  if (filtersValues.keywords?.length) {
    filtersValues.keywords.forEach((keyword) => {
      searchParams.append(
        filtersValuesSearchParamsClassifier.keywords,
        keyword,
      );
    });
  }

  if (filtersValues.work_models?.length) {
    filtersValues.work_models.forEach(({ id }) => {
      searchParams.append(
        filtersValuesSearchParamsClassifier.work_models,
        fromWorkModelToSearchParams[id],
      );
    });
  }
  if (filtersValues.employment_levels?.length) {
    filtersValues.employment_levels.forEach(({ id }) => {
      searchParams.append(
        filtersValuesSearchParamsClassifier.employment_levels,
        employmentLevelsToSearchParams[id],
      );
    });
  }
  if (filtersValues.employment_types?.length) {
    filtersValues.employment_types.forEach(({ id }) => {
      searchParams.append(
        filtersValuesSearchParamsClassifier.employment_types,
        id.toString(),
      );
    });
  }
  if (filtersValues.job_occupations) {
    filtersValues.job_occupations.forEach(({ id }) => {
      searchParams.append(
        filtersValuesSearchParamsClassifier.job_occupations,
        jobsCategoriesShort[jobsCategoriseSearchParams[id]],
      );
    });
  }
  searchParams.append(
    filtersValuesSearchParamsClassifier.experience_years_min,
    workExperienceYearsToValues(
      filtersValues.experience_years_min,
      filtersValues.experience_years_max,
    ),
  );
  return searchParams;
};

export const filtersSWRValuesToSearchParams = (
  filtersValues: ISWRFiltersValuesDto,
): URLSearchParams => {
  const searchParams = new URLSearchParams();

  if (filtersValues?.salary_min) {
    searchParams.set(
      filtersValuesSearchParamsClassifier.salary_min,
      filtersValues.salary_min.toString(),
    );
  }
  if (filtersValues?.country_id) {
    searchParams.set(
      filtersValuesSearchParamsClassifier.country_id,
      filtersValues.country_id,
    );
  }
  if (filtersValues?.cities?.length) {
    filtersValues.cities.forEach((city) => {
      searchParams.append(
        filtersValuesSearchParamsClassifier.cities,
        city.id.toString(),
      );
    });
  }

  if (filtersValues?.keywords?.length) {
    filtersValues.keywords.forEach((keyword) => {
      searchParams.append(
        filtersValuesSearchParamsClassifier.keywords,
        keyword,
      );
    });
  }

  if (filtersValues?.work_models?.length) {
    filtersValues.work_models.forEach(({ id }) => {
      searchParams.append(
        filtersValuesSearchParamsClassifier.work_models,
        fromWorkModelToSearchParams[id],
      );
    });
  }
  if (filtersValues?.employment_levels?.length) {
    filtersValues.employment_levels.forEach((id) => {
      searchParams.append(
        filtersValuesSearchParamsClassifier.employment_levels,
        employmentLevelsToSearchParams[id],
      );
    });
  }
  if (filtersValues?.employment_types?.length) {
    filtersValues.employment_types.forEach((id) => {
      searchParams.append(
        filtersValuesSearchParamsClassifier.employment_types,
        id.toString(),
      );
    });
  }
  if (filtersValues?.jobCategories) {
    filtersValues.jobCategories.forEach((id) => {
      searchParams.append(
        filtersValuesSearchParamsClassifier.job_occupations,
        jobsCategoriesShort[jobsCategoriseSearchParams[id]],
      );
    });
  }
  if (filtersValues?.experience_years_min)
    searchParams.append(
      filtersValuesSearchParamsClassifier.experience_years_min,
      workExperienceYearsToValues(
        filtersValues.experience_years_min,
        filtersValues.experience_years_max,
      ),
    );

  return searchParams;
};
