import threeImage from 'public/images/no-bias.webp';
import theme from 'theme/theme';

export const homeBenefitsCardThreeStyles = {
  benefitCardThree: {
    zIndex: 2,
    backgroundColor: '#E9F7F5',
    borderRadius: '25px',
    flexFlow: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    width: '100%',
    maxWidth: '380px',
    height: '590px',
    padding: '30px 15px',
    display: 'flex',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      height: '570px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '590px',
    },
  },
  three: {
    position: 'absolute',
    backgroundImage: `url("${threeImage.src}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0',
    backgroundSize: 'contain',
    top: '0',
    bottom: '10%',
    left: 0,
    right: 0,
  },
};
