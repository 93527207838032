/* eslint-disable react/jsx-props-no-spreading */
import React, { ComponentProps } from 'react';
import {
  Box,
  Button as MuiButton,
  CircularProgress,
  PropTypes,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

const isMaterialColor = (color: string) =>
  color === 'default' ||
  color === 'inherit' ||
  color === 'primary' ||
  color === 'secondary';

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    position: 'relative',
  },
  button: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    height: 48,
    boxShadow: 'none',
    whiteSpace: 'nowrap',
  },
  buttonSmall: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: 32,
  },
  fullWidth: {
    width: '100%',
  },
  paleGrey: {
    backgroundColor: (theme.palette as any).paleGrey.main,
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

interface IButton {
  children: React.ReactNode;
  color?: PropTypes.Color;
  className?: string;
  fullWidth?: boolean;
  ml?: number;
  mt?: number;
  mr?: number;
  mb?: number;
  flexGrow?: number;
  loading?: boolean;
  sxWrapper?: any;
  ariaLabel: string;
  [x: string]: any;
}

export type Ref = HTMLButtonElement;

export const Button = React.forwardRef<Ref, IButton>(
  (
    {
      children,
      color,
      className,
      fullWidth,
      ml,
      mt,
      mr,
      mb,
      flexGrow,
      loading,
      sxWrapper,
      ariaLabel,
      ...props
    },
    ref,
  ) => {
    const classes = useStyles();

    return (
      <Box
        ml={ml}
        mt={mt}
        mr={mr}
        mb={mb}
        flexGrow={flexGrow}
        className={classes.wrapper}
        sx={sxWrapper}
      >
        <MuiButton
          aria-label={ariaLabel}
          variant="contained"
          color={
            (isMaterialColor(color) ? color : 'primary') as ComponentProps<
              typeof MuiButton
            >['color']
          }
          className={classnames(
            className,
            fullWidth && classes.fullWidth,
            !isMaterialColor(color) && classes[color],
          )}
          ref={ref}
          classes={{ root: classes.button, sizeSmall: classes.buttonSmall }}
          {...props}
        >
          {children}
        </MuiButton>
        {loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </Box>
    );
  },
);
Button.defaultProps = {
  className: '',
  fullWidth: false,
  color: '',
  ml: 0,
  mt: 0,
  mr: 0,
  mb: 0,
  flexGrow: 0,
  loading: false,
  sxWrapper: {},
};
// Needed to forward refs for click away listener
Button.displayName = 'Button';
export default Button;
