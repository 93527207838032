import React from 'react';
import useTranslate from 'utils/i18n/useTranslate';
import { useAppDispatch, useAppSelector } from 'hooks/*';
import { closeCreateAccountModal } from 'store/reducers/uiReducer';
import { ModalWithCloseOnBack } from 'components/ui/';
import { CreateAccountForm } from '../create-account-form';

export const CreateAccountModal = () => {
  const { t } = useTranslate();
  const showModal = useAppSelector(({ ui }) => ui.showCreateAccountModal);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(closeCreateAccountModal());
  };

  return (
    <ModalWithCloseOnBack
      open={showModal}
      onClose={handleClose}
      title={t('createAccount.title')}
      fixedWidth
      PaperProps={{
        id: 'create-account-modal',
        'area-labelledby': 'open-create-account-button',
      }}
      keepMounted={false}
      titleComponent="h2"
      addHash={false}
    >
      <CreateAccountForm open={showModal} />
    </ModalWithCloseOnBack>
  );
};
