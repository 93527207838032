import React, { FC } from 'react';
import { Box } from '@mui/material';
import {
  HomeBenefits,
  HomeCategories,
  HomeHero,
  HomePartners,
  HomeTestimonials,
} from 'components/home';
import { HomeFooter } from 'components/home/home-footer';
import { HomeRemoteWork } from 'components/home/home-remote-work';
import { Layout } from 'components/layout';

/**
 * Home component.
 *
 * @returns {React.ReactElement} The rendered home page.
 */
const Home: FC = (): React.ReactElement => (
  <>
    <Layout>
      <Box
        sx={{
          backgroundColor: '#fff',
        }}
      >
        <Box
          sx={{
            maxWidth: '1600px',
            margin: 'auto',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#F7F7F7',
            }}
          >
            <HomeHero />
            <HomeCategories />
          </Box>
          <Box
            sx={{
              width: '100%',
              height: '5px',
              borderRadius: '87px',
              background:
                'linear-gradient(90deg, #D9D9D9 -0.76%, #15E7B5 -0.75%, #FFCD3D 18.15%, #EA4335 43.84%, #6676FF 69.53%, #130086 100.65%)',
              filter: 'blur(7.5px)',
            }}
          />
          <HomePartners />
          <HomeBenefits />
          <HomeRemoteWork />
          <HomeTestimonials />
          {/* <HomeCareer /> */}
          <HomeFooter />
        </Box>
      </Box>
    </Layout>
  </>
);

export default Home;
