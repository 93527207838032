import { FC, PropsWithChildren } from 'react';
import { DeepLinksModal } from '../deep-links-modal';
import { IModalWithDeepLinks } from './modal-with-deep-links.interface';

export const ModalWithDeepLinks: FC<PropsWithChildren<IModalWithDeepLinks>> = ({
  children,
  onClose,
  open,
  title,
  fixedWidth,
  titleComponent = 'h1',
  ...props
}) => (
  <DeepLinksModal
    open={open}
    title={title}
    titleComponent={titleComponent}
    {...props}
  >
    {children}
  </DeepLinksModal>
);
