import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import useSliderLabelStyles from '../salary-label-format/salary-label-format.styles';
import { IExperienceLabelFormat } from './experience-label-format.interface';

const getLevelYears = (value, levels) => {
  if (levels && levels.length) {
    // "0"
    if (value === 0) return levels[value].min;

    // "8+"
    if (value === levels.length - 1) return `${levels[value].min}+`;

    // "1-3"
    if (value > 0) return `${levels[value].min}-${levels[value].max}`;
  }
  return '';
};

export const ExperienceLabelFormat: FC<IExperienceLabelFormat> = ({
  value = 0,
  levels = [],
  translations: { yearsOfExperience = 'years of experience' },
}) => {
  const classes = useSliderLabelStyles();

  return (
    <Box className={classes.tooltipTextContainer}>
      <Typography className={classes.primaryText}>
        {getLevelYears(value, levels)} {yearsOfExperience}
      </Typography>
    </Box>
  );
};
