import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import useTranslate from 'utils/i18n/useTranslate';
import { useFiltersValues } from 'utils/swr/hooks/useFiltersValues';
import { IHomeCategoryItem } from './home-category-item.interface';
import {
  homeCategoryItemStyles,
  HomeCategoryLink,
} from './home-category-item.styles';

export const HomeCategoryItem: FC<IHomeCategoryItem> = ({
  title,
  iconClass,
  link,
  id,
}) => {
  const { t } = useTranslate('home');
  const { saveJobCategories } = useFiltersValues();
  const onCategoryClick = async () => {
    await saveJobCategories([id]);
  };
  return (
    <HomeCategoryLink
      href={link}
      aria-label={`${t('marketing.jobsIn')} ${title}`}
      onClick={onCategoryClick}
    >
      <Box sx={homeCategoryItemStyles.container}>
        <Box sx={homeCategoryItemStyles.icon(iconClass)} />
        <Typography variant="body2" sx={homeCategoryItemStyles.text}>
          {t('marketing.jobsIn')} {title}
        </Typography>
      </Box>
    </HomeCategoryLink>
  );
};
