/* eslint-disable react/jsx-props-no-spreading */
import { FC, useState } from 'react';
import {
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
} from '@mui/material';
import { StandardTextFieldProps } from '@mui/material/TextField/TextField';
import { makeStyles } from '@mui/styles';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldProps } from 'formik/dist/Field';
import classnames from 'classnames';

export interface ITextField extends Partial<StandardTextFieldProps> {
  field: Partial<FieldProps['field']>;
  meta?: FieldProps['meta'];
  withSecureTextEntry?: boolean;
  type?: string;
}

const useStyles = makeStyles((theme: any) => ({
  iconButton: {
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  input: {
    fontSize: '1rem',
    fontWeight: 600,
  },
  errorInput: {
    color: theme.palette.error.main,
  },
  inputUnderline: {
    borderBottomColor: theme.palette.grey.main,
  },
  label: {
    fontSize: '1rem',
    fontWeight: 600,
    color: theme.palette.darkGrey.main,
  },
  helperText: {
    fontWeight: 600,
  },
  desktopScroll: {
    [theme.breakpoints.up('md')]: {
      overflowY: 'auto',
      maxHeight: 'calc(100vh - 200px)',
      // firefox
      scrollbarWidth: 'thin',
      scrollbarColor: theme.palette.success.main,
      // chrome
      /* width */
      '&::-webkit-scrollbar': {
        width: 8,
        zIndex: 0,
      },
      /* Track */
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
      },

      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.success.main,
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: theme.palette.common.white,
        borderRadius: 8,
      },
    },
  },
}));

export const TextField: FC<ITextField> = ({
  field: { onChange, onBlur, value, name },
  meta: { touched, error } = {
    error: '',
    touched: false,
    value: undefined,
    initialTouched: false,
  },
  type,
  helperText = ' ',
  withSecureTextEntry,
  InputProps = {},
  InputLabelProps = {},
  multiline,
  variant = 'standard',
  ...props
}) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <MuiTextField
      {...props}
      variant={variant}
      multiline={multiline}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      error={touched && !!error}
      helperText={touched && !!error ? error : helperText}
      name={name}
      /* eslint-disable-next-line no-nested-ternary */
      type={withSecureTextEntry ? (showPassword ? 'text' : 'password') : type}
      color="secondary"
      InputProps={{
        endAdornment: withSecureTextEntry && (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              classes={{ root: classes.iconButton }}
            >
              {showPassword ? (
                <FontAwesomeIcon icon={faEye as IconProp} size="xs" />
              ) : (
                <FontAwesomeIcon icon={faEyeSlash as IconProp} size="xs" />
              )}
            </IconButton>
          </InputAdornment>
        ),
        classes: {
          input: classnames(
            classes.input,
            InputProps.classes?.input,
            multiline && classes.desktopScroll,
            touched && !!error && classes.errorInput,
          ),
          underline: classes.inputUnderline,
          ...InputProps.classes,
        },
        ...InputProps,
      }}
      InputLabelProps={{
        classes: {
          root: classes.label,
        },
        ...InputLabelProps,
      }}
      FormHelperTextProps={{
        classes: {
          root: classes.helperText,
        },
      }}
    />
  );
};

export default TextField;
