import oneImage from 'public/images/perfect-fit.webp';
import theme from 'theme/theme';

export const homeBenefitsCardOneStyles = {
  benefitCard: {
    zIndex: 2,
    backgroundColor: '#e6e3f7',
    borderRadius: '25px',
    flexFlow: 'column',
    flex: 'none',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    width: '100%',
    maxWidth: '380px',
    height: '590px',
    padding: '30px 15px',
    display: 'flex',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      height: '570px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '590px',
    },
  },
  one: {
    position: 'absolute',
    backgroundImage: `url("${oneImage.src}")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '90%',
    backgroundPositionX: 'center',
    top: '160px',
    bottom: 0,
    left: '10%',
    right: '10%',
  },
};
