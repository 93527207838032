import { FC, PropsWithChildren } from 'react';
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Transition } from '@nploy/ui/web';
import { deepLinksModalStyles } from 'components/ui/deep-links-modal/deep-links-modal.styles';
import { IDeepLinksModal } from './deep-links-modal.interface';

export const DeepLinksModal: FC<PropsWithChildren<IDeepLinksModal>> = ({
  children,
  onClose,
  open,
  title,
  titleComponent = 'h2',
  ...props
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby={title}
    TransitionComponent={Transition}
    keepMounted
    sx={deepLinksModalStyles.root}
    style={{ zIndex: 1350 }}
    {...props}
  >
    <DialogTitle id={title} sx={deepLinksModalStyles.title}>
      <Typography variant="h4" component={titleComponent}>
        {title}
      </Typography>
    </DialogTitle>

    <DialogContent sx={deepLinksModalStyles.content}>{children}</DialogContent>
  </Dialog>
);

export default DeepLinksModal;
