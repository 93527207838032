import theme from 'theme/theme';

export const rightPanelStyles = {
  rightPanel: {
    maxWidth: 310,
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
  },
  panelButton: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(3),
    },
  },
  hideSmDown: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  hideMdUp: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  signInButton: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(3),
    },
    backgroundColor: 'transparent',
    border: `2px solid ${theme.palette.darkBlue.main}`,
    borderRadius: 25,
    color: theme.palette.darkBlue.main,
    '&:hover': {
      backgroundColor: theme.palette.darkBlue.main,
      color: theme.palette.common.white,
    },
  },
  hrLoginButton: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(3),
    },
    display: 'flex',
    alignItems: 'center',
    width: 'max-content',
    backgroundColor: theme.palette.darkBlue.main,
    '&:hover': {
      backgroundImage: `linear-gradient(101deg, #15e7b5, #f0ffac)`,
      color: theme.palette.common.black,
    },
    borderRadius: 25,
    color: theme.palette.common.white,
    height: 48,
    fontSize: '1rem',
    lineHeight: '1.75',
    fontWeight: 600,
    padding: '6px 24px',
  },
};
