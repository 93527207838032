import { useEffect, useRef, useState, useCallback } from 'react';
import { useRouter } from 'next/router';
import { ICandidateJobDto } from '@nploy/ui-infrastructure';

export const useShowJobDetails = () => {
  const router = useRouter();
  const previousQueryValue = useRef<string>(
    router.query['job-details'] as string,
  );
  const [showJobDetails, setShowJobDetails] = useState<ICandidateJobDto | null>(
    null,
  );

  const handleSetShowJobDetails = useCallback(
    (details: ICandidateJobDto | null) => {
      setShowJobDetails(details);
    },
    [],
  );

  useEffect(() => {
    const currentJobDetails = router.query['job-details'];

    if (!currentJobDetails && previousQueryValue.current) {
      handleSetShowJobDetails(null);
    }

    previousQueryValue.current = currentJobDetails as string;
  }, [router.query, handleSetShowJobDetails]);

  return {
    showJobDetails,
    setShowJobDetails: handleSetShowJobDetails,
  };
};
