import { FC, useCallback, useRef, useState } from 'react';
import Link from 'next/link';
import {
  Box,
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import useTranslate from 'utils/i18n/useTranslate';
import { ArrowDown } from 'components/ui/icons/arrow-down';
import { IDropdownNavLinks } from './dropdown-nav-links.interface';
import { dropdownNavLinksStyles } from './dropdown-nav-links.styles';

export const DropdownNavLinks: FC<IDropdownNavLinks> = ({
  dropdownTitle,
  links,
  inDrawer,
}) => {
  const { t } = useTranslate();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Box
        sx={dropdownNavLinksStyles.navDropdown(inDrawer)}
        ref={anchorRef}
        onClick={handleToggle}
      >
        {dropdownTitle}{' '}
        <Typography px={1} sx={dropdownNavLinksStyles.arrow(open)}>
          <ArrowDown />
        </Typography>
      </Box>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 1000 }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {links.map((link) => (
                    <Link
                      key={link.text}
                      href={link.link}
                      style={{
                        textDecoration: 'none',
                      }}
                      target={
                        (link.link as string).startsWith('http')
                          ? '_blank'
                          : undefined
                      }
                      onClick={handleClose}
                    >
                      <Typography
                        px={2}
                        py={1}
                        sx={dropdownNavLinksStyles.link}
                      >
                        {t(link.text)}
                      </Typography>
                    </Link>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
