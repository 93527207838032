import { useEffect, useState } from 'react';
import { CandidatesRoutes } from 'constants/routes';
import { filtersValuesToSearchParams } from 'utils/ssr/filters-values-to-search-params';
import { useFiltersValues } from 'utils/swr/hooks/useFiltersValues';
import {
  useEmploymentLevelsQuery,
  useEmploymentTypesQuery,
} from 'store/services';

export const useHomePageJobsUrl = () => {
  const [jobsUrl, setJobsUrl] = useState<string>(CandidatesRoutes.jobs);
  const { filtersValues } = useFiltersValues();

  const { data: employmentLevels } = useEmploymentLevelsQuery();
  const { data: employmentTypes } = useEmploymentTypesQuery();

  useEffect(() => {
    const searchParams = filtersValuesToSearchParams({
      ...filtersValues,
      job_occupations: undefined,
      employment_levels: (employmentLevels || []).filter((level) =>
        filtersValues.employment_levels.includes(level.id),
      ),
      employment_types: (employmentTypes || []).filter((type) =>
        filtersValues.employment_types.includes(type.id),
      ),
    });
    setJobsUrl(`/${CandidatesRoutes.jobs}?${searchParams.toString()}`);
  }, [filtersValues, employmentLevels, employmentTypes]);

  return { jobsUrl };
};
