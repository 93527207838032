import React, { FC, useCallback, useRef, useState } from 'react';
import {
  Box,
  ButtonBase,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  SxProps,
  Theme,
} from '@mui/material';
import flags from 'constants/flags';
import useTranslate from 'utils/i18n/useTranslate';
import { LanguageMenu } from 'components/layout/Header/language-menu';
import { IDesktopLanguageMenu } from './desktop-language-menu.interface';
import {
  DesktopLanguageMenuImage,
  desktopLanguageMenuStyles,
} from './desktop-language-menu.styles';

export const DesktopLanguageMenu: FC<IDesktopLanguageMenu> = ({
  image,
  sxContainer,
  sx,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const { t, lang } = useTranslate();

  const handleToggle = useCallback(() => {
    setOpen((prevState) => !prevState);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleListKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      }
    },
    [],
  );

  return (
    <Box sx={sxContainer} position="relative">
      <ButtonBase
        ref={anchorRef}
        sx={
          {
            ...sx,
            ...desktopLanguageMenuStyles.imageCircleButton,
            ...(open && desktopLanguageMenuStyles.buttonActive),
            ...(!image && desktopLanguageMenuStyles.placeholderBackground),
          } as SxProps<Theme>
        }
        onClick={handleToggle}
        id="language-menu-button"
        aria-label="language-menu"
      >
        <Box sx={desktopLanguageMenuStyles.imageContainer}>
          <DesktopLanguageMenuImage
            src={flags[lang.toUpperCase()]}
            alt={`${t('img.flagOfAlt')} ${t(`languages.countries.${lang}`)}`}
            title={t(`languages.${lang}`)}
          />
        </Box>
      </ButtonBase>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        style={{ zIndex: 5 }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
            <Paper sx={desktopLanguageMenuStyles.menuContainer}>
              <ClickAwayListener onClickAway={handleClose}>
                <LanguageMenu
                  open={open}
                  onListKeyDown={handleListKeyDown}
                  onClose={handleClose}
                />
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};
