import { FC, ReactNode } from 'react';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { homeTestimonialsCommentOneStyles } from './home-testimonials-comment-one.styles';

export const HomeTestimonialsCommentOne: FC<{
  text: ReactNode | string;
  author: string;
  styles?: SxProps<Theme>;
  ninjaIconStyles?: SxProps<Theme>;
}> = ({ author, text, styles, ninjaIconStyles }) => (
  <Box sx={styles || homeTestimonialsCommentOneStyles.popupQuotes}>
    <Box sx={homeTestimonialsCommentOneStyles.popupQuotesTexWrap}>
      <Typography
        component="span"
        sx={homeTestimonialsCommentOneStyles.popupQuotesText}
      >
        {text}
      </Typography>
    </Box>
    <Typography component="h4" sx={homeTestimonialsCommentOneStyles.author}>
      {author}
    </Typography>
    <Box
      sx={
        {
          ...homeTestimonialsCommentOneStyles.ninjaIcon,
          ...ninjaIconStyles,
        } as SxProps<Theme>
      }
    />
  </Box>
);
