import { FC, useMemo } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import theme from 'theme/theme';
import useTranslate from 'utils/i18n/useTranslate';
import { HomeHeroLottie } from 'components/home/home-hero-lottie';
import { homeHeroStyles } from './home-hero.styles';

export const HomeHero: FC = () => {
  const { t } = useTranslate('home');
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const title = useMemo(() => {
    if (isDesktop) {
      return (
        <Typography component="h1" sx={homeHeroStyles.title}>
          {t('marketing.desktopTitleFirst')}{' '}
          <Typography component="span" sx={homeHeroStyles.titleSecond}>
            {t('marketing.desktopTitleSecond')}
          </Typography>
          <Typography
            component="span"
            sx={{ ...homeHeroStyles.title, fontWeight: 400 }}
          >
            {t('marketing.desktopTitleThird')}
          </Typography>
          <Typography component="span" sx={homeHeroStyles.titleHero}>
            {t('marketing.titleYou')}
          </Typography>
          <Typography
            component="span"
            sx={{ ...homeHeroStyles.title, fontWeight: 400 }}
          >
            {t('marketing.desktopTitleFourth')}
          </Typography>
        </Typography>
      );
    }

    return (
      <Typography component="h1" sx={homeHeroStyles.title}>
        {t('marketing.titleFirst')}
        <Typography component="span" sx={homeHeroStyles.titleSecond}>
          {t('marketing.titleSecond')}
        </Typography>
        <Typography component="span" sx={homeHeroStyles.title}>
          {t('marketing.titleThird')}
        </Typography>
        <Typography
          component="span"
          sx={{ ...homeHeroStyles.title, fontWeight: 400 }}
        >
          {t('marketing.titleFourth')}
        </Typography>
        <Typography component="span" sx={homeHeroStyles.titleHero}>
          {t('marketing.titleYou')}
        </Typography>
        <Typography
          component="span"
          sx={{ ...homeHeroStyles.title, fontWeight: 400 }}
        >
          {t('marketing.titleFifth')}
        </Typography>
      </Typography>
    );
  }, [isDesktop, t]);
  return (
    <Box sx={homeHeroStyles.root}>
      <Box sx={homeHeroStyles.content}>
        <Box sx={homeHeroStyles.contentHero}>
          <Box sx={homeHeroStyles.titleWrapper}>{title}</Box>
          <Box sx={homeHeroStyles.phone}>
            <HomeHeroLottie
              getAnimationData={() => import('./HomePageSwipeCard.json')}
              loop
              id="HomePageSwipeCard"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
