import { Box, Typography } from '@mui/material';
import useTranslate from 'utils/i18n/useTranslate';
import {
  homeBenefitsStyles,
  MoreLink,
} from 'components/home/home-benefits/home-benefits.styles';
import { homeBenefitsCardOneStyles } from './home-benefits-card-one.styles';

export const HomeBenefitsCardOne = () => {
  const { t } = useTranslate('home');
  return (
    <Box sx={homeBenefitsCardOneStyles.benefitCard}>
      <Box sx={homeBenefitsStyles.cardHeader}>
        <Typography variant="h3" sx={homeBenefitsStyles.cardTitle}>
          {t('marketing.smart')} <br />
          {t('marketing.algorithm')}
        </Typography>
        <MoreLink
          href="/post/how-does-our-matching-algorithm-work"
          aria-label={t('marketing.more')}
        >
          {t('marketing.more')}
        </MoreLink>
      </Box>
      <Box>
        <Typography variant="body2" sx={homeBenefitsStyles.text}>
          {t('marketing.benefitsCardOneText')}
        </Typography>
        <Typography
          component="strong"
          sx={{
            ...homeBenefitsStyles.text,
            ...homeBenefitsStyles.textBold,
          }}
        >
          {t('marketing.benefitsCardOneTextSecondary')}
        </Typography>
      </Box>
      <Box
        sx={homeBenefitsCardOneStyles.one}
        role="img"
        aria-label="Illustration of nPloy’s AI algorithm matching candidates with ideal employers"
      />
    </Box>
  );
};
