import { Box, Typography } from '@mui/material';
import useTranslate from 'utils/i18n/useTranslate';
import {
  homeBenefitsStyles,
  MoreLink,
} from 'components/home/home-benefits/home-benefits.styles';
import { homeBenefitsCardThreeStyles } from './home-benefits-card-three.styles';

export const HomeBenefitsCardThree = () => {
  const { t } = useTranslate('home');
  return (
    <Box sx={homeBenefitsCardThreeStyles.benefitCardThree}>
      <Box sx={homeBenefitsStyles.cardHeader}>
        <Typography variant="h3" sx={homeBenefitsStyles.cardTitle}>
          {t('marketing.anonymity')}
        </Typography>
        <MoreLink
          href="/post/what-does-it-mean-to-be-anonymous-in-nploy"
          aria-label={t('marketing.more')}
        >
          {t('marketing.more')}
        </MoreLink>
      </Box>
      <Box>
        <Typography variant="body2" sx={homeBenefitsStyles.text}>
          {t('marketing.benefitsCardThreeWith')}
          <Typography
            component="strong"
            sx={{
              ...homeBenefitsStyles.text,
              ...homeBenefitsStyles.textBold,
            }}
          >
            nPloy
          </Typography>
          {t('marketing.benefitsCardThreeIncognito')}
          <Typography
            component="strong"
            sx={{
              ...homeBenefitsStyles.text,
              ...homeBenefitsStyles.textBold,
            }}
          >
            {t('marketing.benefitsCardThreeHighlightYourTalent')}
          </Typography>
        </Typography>
      </Box>
      <Box
        sx={homeBenefitsCardThreeStyles.three}
        role="img"
        aria-label="Image of anonymous candidate profiles emphasizing anonymity and unbiased recruitment on nPloy."
      />
    </Box>
  );
};
