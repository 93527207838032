import twoImage from 'public/images/match-request.webp';
import theme from 'theme/theme';

export const homeBenefitsCardTwoStyles = {
  benefitCardTwo: {
    zIndex: 2,
    backgroundColor: '#D6FAF2',
    borderRadius: '25px',
    flexFlow: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    width: '100%',
    maxWidth: '380px',
    height: '590px',
    padding: '30px 15px',
    display: 'flex',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      height: '620px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '60px 25px',
      height: '620px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '400px',
      height: '660px',
    },
  },
  two: {
    position: 'absolute',
    backgroundImage: `url("${twoImage.src}")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    top: '30%',
    bottom: '123px',
    left: 0,
    right: 0,
  },
};
