import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { captureEvent } from '@sentry/nextjs';
import useSWR from 'swr';
import { axios, deleteAuthToken } from '@nploy/shared';
import { ICandidateUserDto } from '@nploy/ui-infrastructure';
import { CallbackFunction } from 'infrastructure/types';
import { openFeedback } from 'store/reducers/feedbackReducer';
import isWindow from '../../isWindow';
import { fetchData } from '../helpers/fetchData';
import { shouldFetchIfHasToken } from '../helpers/shouldFetch';
import { useAuth } from './useAuth';
import { mutateCV } from './useCV';

const DEFAULT_ERROR = 'Network Error';

const fetchUser = async () => {
  if (!shouldFetchIfHasToken()) return false;
  try {
    return await fetchData<ICandidateUserDto>('user');
  } catch (error) {
    if (error.response.status === 403) {
      deleteAuthToken();
    }
    throw error;
  }
};

export const useUser = () => {
  const dispatch = useDispatch();
  const { signOut } = useAuth();

  const { data, error, mutate } = useSWR<ICandidateUserDto | false>(
    'user',
    fetchUser,
    {
      onErrorRetry: (err, key, config, revalidate, { retryCount }) => {
        // Never retry on 403.
        if (err.status === 403) {
          signOut();
          return false;
        }
        // Only retry up to 3 times. Then delete token
        if (retryCount >= 3) {
          deleteAuthToken();
        }
        // Retry after 7 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 7000);
      },
    },
  );

  const deletePhoto = useCallback(
    async (callback?: CallbackFunction) => {
      try {
        await axios.delete('resume/personal-information/photo');
        if (callback) {
          callback();
        }
      } catch (err) {
        dispatch(openFeedback({ type: 'error', message: err.message }));
        throw err;
      }
    },
    [dispatch],
  );

  const uploadPhoto = useCallback(
    async (file: File) => {
      try {
        const photoData = new FormData();
        photoData.append('image', file);
        await axios.post(
          'resume/personal-information/photo/upload',
          photoData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );
        mutate();
      } catch (err) {
        dispatch(openFeedback({ type: 'error', message: err.message }));
        throw err;
      }
    },
    [dispatch, mutate],
  );

  const savePersonalInfo = useCallback(
    async (personalInfo: ICandidateUserDto) => {
      try {
        await axios.post('resume/personal-information/store', {
          full_name: personalInfo.full_name,
          phone_number: personalInfo.phone_number,
          city_id: personalInfo.city.id,
        });
        await mutate();
        mutateCV();
      } catch (err) {
        dispatch(
          openFeedback({
            type: 'error',
            message:
              err.response.data.errors?.phone_number?.[0] || DEFAULT_ERROR,
          }),
        );
        throw err;
      }
    },
    [dispatch, mutate],
  );

  const changeEmail = useCallback(
    async (email: string) => {
      await mutate({ ...(data && data), email }, false);
      try {
        const res = await axios.post('auth/change-email', {
          email,
        });
        dispatch(openFeedback({ type: 'success', message: res.data.message }));
      } catch (err) {
        dispatch(
          openFeedback({
            type: 'error',
            message: err.response.data.errors?.email?.[0] || DEFAULT_ERROR,
          }),
        );
        throw err;
      }
    },
    [data, dispatch, mutate],
  );

  const deleteAccount = useCallback(async () => {
    try {
      await axios.post('delete-account');
      dispatch(openFeedback({ type: 'success', message: 'userDeleted' }));
      signOut();
    } catch (err) {
      dispatch(
        openFeedback({ type: 'error', message: err.response.data.message }),
      );
      throw err;
    }
  }, [dispatch, signOut]);

  const changePassword = useCallback(
    async (params) => {
      try {
        await axios.post('auth/change-password', params);
        dispatch(openFeedback({ type: 'success', message: 'passwordChanged' }));
      } catch (err) {
        dispatch(
          openFeedback({ type: 'error', message: err.response.data.message }),
        );
        throw err;
      }
    },
    [dispatch],
  );

  const setUserStudentStatus = useCallback(async () => {
    try {
      await axios.post('settings/set-student-status');
    } catch (err) {
      captureEvent(err);
    }
  }, []);

  return {
    user: {
      full_name: data ? data?.full_name : null,
      email: data ? data?.email : null,
      phone_number: data ? data.phone_number : null,
      city: data ? data.city : null,
      photo_url: data ? data.photo_url : '',
      isAuthed: !!data,
    },
    loading:
      isWindow() &&
      shouldFetchIfHasToken() &&
      typeof data === 'undefined' &&
      !error,
    error,
    savePersonalInfo,
    changeEmail,
    uploadPhoto,
    deletePhoto,
    deleteAccount,
    changePassword,
    setUserStudentStatus,
  };
};
