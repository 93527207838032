/* eslint-disable @typescript-eslint/naming-convention */
import { ISWRFiltersValuesDto } from 'types/*';
import { useCallback, useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import useSWR from 'swr';
import { axios } from '@nploy/shared';
import {
  FilterName,
  ICandidateSelectedCityDto,
  IFiltersValuesDto,
} from '@nploy/ui-infrastructure';
import { IWorkModel } from '@nploy/ui/domain';
import { filtersValuesFields } from 'constants/filters-values-fields';
import { CandidatesRoutes } from 'constants/routes';
import { initialFiltersValuesData } from 'constants/user-filters';
import { CallbackFunction } from 'infrastructure/types';
import {
  createAuthedUserFilters,
  createUnAuthedUserFilters,
} from 'utils/helpers';
import transformLocaleFormat from 'utils/i18n/transformLocaleFormat';
import {
  localStorageKeys,
  setCookie,
  setToLocalStorage,
} from 'utils/localStorage';
import { filtersSWRValuesToSearchParams } from 'utils/ssr/filters-values-to-search-params';
import { transformFiltersValuesToSend } from 'utils/swr/helpers';
import { useAppDispatch } from 'hooks/*';
import { openFeedback } from 'store/reducers/feedbackReducer';
import { compareFiltersValues } from '../helpers/compareFiltersValues';
import { fetchData } from '../helpers/fetchData';
import {
  hasCountryIdChanged,
  resetSalaryOnCountryChange,
} from '../helpers/filtersValues';
import { shouldFetchIfHasToken } from '../helpers/shouldFetch';
import { useUser } from './useUser';

const fetcher = async (url: string, lang: string) => {
  if (shouldFetchIfHasToken()) {
    const data = await fetchData<IFiltersValuesDto>(url, {
      locale: transformLocaleFormat(lang),
    });
    return createAuthedUserFilters(data);
  }
  return createUnAuthedUserFilters();
};

export const useFiltersValues = () => {
  const { lang } = useTranslation();
  const dispatch = useAppDispatch();
  const { replace, pathname } = useRouter();
  const {
    user: { isAuthed },
  } = useUser();
  const { data, error, mutate } = useSWR<ISWRFiltersValuesDto>(
    'filters/general/get',
    (url) => fetcher(url, lang),
    {
      shouldRetryOnError: isAuthed,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      compare: compareFiltersValues,
    },
  );

  const mutateByField = useCallback(
    (name: FilterName, value, revalidate = false) => {
      mutate({ ...data!, [name]: value }, revalidate);
    },
    [data, mutate],
  );

  const filtersQueryPath = useMemo(
    () => filtersSWRValuesToSearchParams(data),
    [data],
  );

  const refreshPathWithParams = useCallback(
    async (newFilters: ISWRFiltersValuesDto) => {
      if (pathname === `/${CandidatesRoutes.jobs}`) {
        const searchParams = filtersSWRValuesToSearchParams(newFilters);

        await replace(`/${CandidatesRoutes.jobs}?${searchParams.toString()}`);
      }
    },
    [pathname, replace],
  );

  // Save values
  const saveFieldsWithAllFilters = useCallback(
    async (
      dataToSave: Partial<ISWRFiltersValuesDto>,
      callback?: CallbackFunction,
    ) => {
      const newFilters: ISWRFiltersValuesDto = {
        ...data!,
        ...dataToSave,
      };
      mutate(newFilters, false);
      if (callback) {
        callback();
      }
      refreshPathWithParams(newFilters);
      try {
        if (isAuthed) {
          await axios.post(
            'filters/general/store-all-filters',
            transformFiltersValuesToSend({ ...data!, ...dataToSave }, true),
          );
        } else {
          // eslint-disable-next-line no-restricted-syntax
          for (const key in dataToSave) {
            if (localStorageKeys[key]) {
              setToLocalStorage(localStorageKeys[key], dataToSave[key]);
            }
          }
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const key in dataToSave) {
          if (localStorageKeys[key]) {
            setCookie(localStorageKeys[key], dataToSave[key]);
          }
        }
      } catch (err) {
        dispatch(openFeedback({ type: 'error', message: err.message }));
      } finally {
        mutate();
      }
    },
    [data, dispatch, isAuthed, mutate, refreshPathWithParams],
  );

  const saveField = async ({
    field,
    value,
    callback,
    url,
    requestParams,
    storageKey,
  }) => {
    if (callback) callback();
    try {
      if (isAuthed) {
        mutateByField(field, value);
        await axios.post(url, requestParams);
      } else {
        setToLocalStorage(storageKey, value);
      }
      setCookie(storageKey, value);
    } catch (err) {
      dispatch(openFeedback({ type: 'error', message: err.message }));
    } finally {
      mutate();
    }
  };

  const saveJobCategories = async (
    jobCategories: number[],
    callback?: CallbackFunction,
  ) => {
    refreshPathWithParams({ ...data, jobCategories });
    await saveField({
      field: filtersValuesFields.jobCategories,
      value: jobCategories,
      callback,
      url: 'filters/job-occupations',
      requestParams: { job_occupations: jobCategories },
      storageKey: localStorageKeys.jobCategories,
    });
  };

  const saveCountry = (country_id: string, callback?: CallbackFunction) =>
    saveField({
      field: filtersValuesFields.country_id,
      value: country_id,
      callback,
      url: 'filters/country',
      requestParams: {
        country_id,
      },
      storageKey: localStorageKeys.country_id,
    });

  const saveSalary = async (salaryMin: number, salaryMax: number) => {
    const newFilters = {
      ...data!,
      [filtersValuesFields.salary_min]: salaryMin,
      [filtersValuesFields.salary_max]: salaryMax,
    };
    mutate(newFilters, false);
    refreshPathWithParams(newFilters);
    try {
      if (isAuthed) {
        await axios.post('filters/salary', {
          salary_min: salaryMin,
          salary_max: salaryMax,
          country_id: data!.country_id,
        });
      } else {
        setToLocalStorage(localStorageKeys.salary_min, salaryMin);
        setToLocalStorage(localStorageKeys.salary_max, salaryMax);
      }
      setCookie(localStorageKeys.salary_min, salaryMin);
      setCookie(localStorageKeys.salary_max, salaryMax);
    } catch (err) {
      dispatch(openFeedback({ type: 'error', message: err.message }));
    } finally {
      mutate();
    }
  };

  const saveWorkModels = async (workModels: IWorkModel[]) => {
    await saveFieldsWithAllFilters({ work_models: workModels });
  };

  const saveLocation = useCallback(
    async (
      dataToSave: { country_id: string; cities: ICandidateSelectedCityDto[] },
      callback?: CallbackFunction,
    ) => {
      if (hasCountryIdChanged(data, dataToSave)) {
        const newDataToSave = await resetSalaryOnCountryChange(dataToSave);
        return saveFieldsWithAllFilters(newDataToSave, callback);
      }
      await saveFieldsWithAllFilters(dataToSave, callback);
    },
    [data, saveFieldsWithAllFilters],
  );

  return {
    filtersValues: data || initialFiltersValuesData,
    loading: !error && !data,
    error,
    mutateByField,
    mutate,
    saveJobCategories,
    saveCountry,
    saveSalary,
    saveLocation,
    saveFieldsWithAllFilters,
    initialFilters: initialFiltersValuesData,
    saveWorkModels,
    filtersQueryPath,
  };
};
