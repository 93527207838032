import ninjaIcon from 'public/images/icons/ninja-icon-2.svg';
import theme from 'theme/theme';

export const homeTestimonialsCommentOneStyles = {
  popupQuotes: {
    zIndex: 5,
    color: '#fff',
    textAlign: 'left',
    flexFlow: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxWidth: '140px',
    width: '100%',
    position: 'absolute',
    top: '10%',
    left: '60%',
    transform: 'rotate(12deg)',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '220px',
      left: '70%',
    },
    [theme.breakpoints.up('md')]: {
      left: '80%',
      top: '8%',
      maxWidth: '275px',
    },
    [theme.breakpoints.up('lg')]: {
      left: '100%',
      top: '8%',
    },
  },
  popupQuotesTexWrap: {
    display: 'flex',
    padding: '6.884px 20.652px 6.884px 14.686px',
    gap: '4.589px',
    borderRadius: '13.768px',
    background: 'linear-gradient(295deg, #150090 28.12%, #4A37B9 91.01%)',
    boxShadow: '30.749px -4.589px 72.972px 0px rgba(102, 145, 255, 0.20)',
  },
  popupQuotesText: {
    color: '#FFF',
    fontSize: '8.261px',
    fontWeight: 400,
    [theme.breakpoints.up('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '15px',
    },
  },
  ninjaIcon: {
    width: '26.216px',
    height: '26.216px',
    position: 'absolute',
    left: '-18.135px',
    top: '1.036px',
    borderRadius: '50%',
    background: `url(${ninjaIcon.src})`,
  },
  author: {
    color: '#FFF',
    fontSize: '7.141px',
    fontWeight: 300,
    [theme.breakpoints.up('sm')]: {
      fontSize: '9px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '11px',
    },
  },
};
