import React, { FC } from 'react';
import { Box, Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material';
import { CandidatesRoutes } from 'constants/routes';
import useTranslate from 'utils/i18n/useTranslate';
import { Link } from '../next-link';
import { IBreadcrumbsProps } from './breadcrumbs.interface';
import { breadcrumbsStyles } from './breadcrumbs.styles';

export const Breadcrumbs: FC<IBreadcrumbsProps> = ({
  crumbs = [],
  currentCountry,
  currentPageName,
}) => {
  const { t } = useTranslate();

  return (
    <Box mb={2} component="nav">
      <MuiBreadcrumbs aria-label="breadcrumb">
        <Typography color="textSecondary" sx={breadcrumbsStyles.link}>
          <Link href={`/${CandidatesRoutes.jobs}`} passHref>
            {t('breadcrumbs.home')}
          </Link>
        </Typography>

        {currentCountry !== currentPageName ? (
          <Typography color="textSecondary" sx={breadcrumbsStyles.link}>
            <Link href={`/${CandidatesRoutes.jobs}/${currentCountry}`} passHref>
              {t(`SEOTables.${currentCountry}`)}
            </Link>
          </Typography>
        ) : (
          <Typography color="textPrimary">
            {t(`SEOTables.${currentCountry}`)}
          </Typography>
        )}

        {crumbs.map(({ name, href }) => (
          <Typography
            key={href}
            color="textSecondary"
            sx={breadcrumbsStyles.link}
          >
            <Link href={`/${href}`} passHref>
              {name}
            </Link>
          </Typography>
        ))}

        {currentPageName && currentCountry !== currentPageName && (
          <Typography color="textPrimary">{currentPageName}</Typography>
        )}
      </MuiBreadcrumbs>
    </Box>
  );
};
