export const dropdownNavLinks = {
  popularJobCategories: {
    title: 'navigation.popularJobCategories',
    links: [
      {
        link: '/jobs?category=IT',
        text: 'navigation.informationTechnologyJobs',
      },
      {
        link: '/jobs?category=Customer',
        text: 'navigation.customerServiceJobs',
      },
      {
        link: '/jobs?category=Marketing',
        text: 'navigation.marketingJobs',
      },
      {
        link: '/jobs?category=Sales',
        text: 'navigation.salesJobs',
      },
      {
        link: '/jobs?category=Accounting',
        text: 'navigation.accountingFinanceJobs',
      },
      {
        link: '/jobs?category=Business',
        text: 'navigation.businessManagementJobs',
      },
    ],
  },
  forCompanies: {
    title: 'navigation.forCompanies',
    links: [
      {
        link: '/business/for-employers',
        text: 'navigation.forEmployers',
      },
      {
        link: '/business/ats-integrations',
        text: 'navigation.atsIntegrations',
      },
      // {
      //   link: '/business/employer-branding',
      //   text: 'navigation.employerBranding',
      // },
      {
        link: '/business/pricing',
        text: 'navigation.pricing',
      },
    ],
  },
  resources: {
    title: 'navigation.resources',
    links: [
      {
        link: 'https://resources.nploy.net/blog/home',
        text: 'navigation.blog',
      },
    ],
  },
  // aboutUs: {
  //   title: 'navigation.aboutUs',
  //   links: [
  //     {
  //       link: '/about-us/our-story',
  //       text: 'navigation.story',
  //     },
  //     {
  //       link: '/about-us/our-values',
  //       text: 'navigation.values',
  //     },
  //     {
  //       link: '/about-us/our-mobile-app',
  //       text: 'navigation.mobileApp',
  //     },
  //   ],
  // },
  quickLinks: {
    title: 'navigation.quickLinks',
    links: [
      {
        link: '/get-in-touch',
        text: 'navigation.contactUs',
      },
      {
        link: '/terms-and-conditions',
        text: 'navigation.termsAndConditions',
      },
      {
        link: '/privacy-policy',
        text: 'navigation.privacyPolicy',
      },
    ],
  },
} as const;
