import { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import phone from 'public/images/cta-phones.webp';
import useTranslate from 'utils/i18n/useTranslate';
import { homeFooterStyles, Image } from './home-footer.styles';

export const HomeFooter: FC = () => {
  const { t } = useTranslate('home');
  return (
    <Box sx={homeFooterStyles.root}>
      <Box sx={homeFooterStyles.contentRoot}>
        <Box
          sx={{
            maxWidth: '800px',
            margin: '0 auto',
            position: 'relative',
          }}
        >
          <Typography sx={homeFooterStyles.title}>
            {t('marketing.footerTitle')}
          </Typography>
          <Typography variant="body1" sx={homeFooterStyles.subtitle}>
            {t('marketing.footerSubtitleOne')} <strong>nPloy</strong>
            {t('marketing.footerSubtitleSecond')}
          </Typography>
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Button sx={homeFooterStyles.getStartedButton}>
              {t('marketing.footerGetStarted')}
            </Button>
            <Box sx={homeFooterStyles.storesButtons}>
              <Button
                sx={homeFooterStyles.linkAppStore}
                aria-label="Download on the app store"
              />
              <Button
                sx={homeFooterStyles.linkGooglePlay}
                aria-label="Get it on Google play"
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={homeFooterStyles.ctaPhones}>
        <Image
          unoptimized
          loading="lazy"
          src={phone}
          alt="Motivated professional ready to start their dream career journey and find the perfect job match with nPloy"
        />
      </Box>
    </Box>
  );
};
