import { Box, Typography } from '@mui/material';
import useTranslate from 'utils/i18n/useTranslate';
import {
  homeBenefitsStyles,
  MoreLink,
} from 'components/home/home-benefits/home-benefits.styles';
import { homeBenefitsCardTwoStyles } from './home-benefits-card-two.styles';

export const HomeBenefitsCardTwo = () => {
  const { t } = useTranslate('home');
  return (
    <Box sx={homeBenefitsCardTwoStyles.benefitCardTwo}>
      <Box sx={homeBenefitsStyles.cardHeader}>
        <Typography variant="h3" sx={homeBenefitsStyles.cardTitle}>
          {t('marketing.match')}
        </Typography>
        <MoreLink
          href="/post/you-a-dream-job-a-match-made-on-nploy"
          aria-label={t('marketing.more')}
        >
          {t('marketing.more')}
        </MoreLink>
      </Box>
      <Box>
        <Typography variant="body2" sx={homeBenefitsStyles.text}>
          {t('marketing.benefitsCardTwoText')}
        </Typography>
        <Typography
          component="strong"
          sx={{
            ...homeBenefitsStyles.text,
            ...homeBenefitsStyles.textBold,
          }}
        >
          {t('marketing.benefitsCardTwoTextSecondary')}
        </Typography>
        <br />
        <Typography
          component="strong"
          sx={{
            ...homeBenefitsStyles.text,
            ...homeBenefitsStyles.textBold,
          }}
        >
          {t('marketing.benefitsCardTwoTextThird')}
        </Typography>
      </Box>
      <Box
        sx={homeBenefitsCardTwoStyles.two}
        role="img"
        aria-label="Graphic showing a push notification from an employer sending a job match request to a candidate on nPloy."
      />
    </Box>
  );
};
