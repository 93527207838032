import phone from 'public/images/home/transparent-phone.webp';
import theme from 'theme/theme';

export const homeHeroStyles = {
  root: {
    paddingTop: '40px',
    paddingLeft: '10px',
    paddingRight: '10px',
    maxWidth: '1332px',
    margin: '0 auto',
  },
  content: {
    display: 'flex',
    padding: '150px 0px 50px 0px',
    flexDirection: 'column',
    borderRadius: '37px',
    background:
      'radial-gradient(66.7% 189.84% at 50% -83.61%, #A89DE9 25.73%, #15008F 58.76%, #090437 94.61%)',
    [theme.breakpoints.up('sm')]: {
      padding: 0,
    },
    [theme.breakpoints.up('lg')]: {
      maxHeight: '380px',
    },
  },
  contentHero: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'stretch',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
  },
  titleWrapper: {
    paddingLeft: '10px',
    paddingRight: '10px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '80px',
      marginBottom: '100px',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '122px',
      marginBottom: '137px',
    },
    [theme.breakpoints.up(780)]: {
      marginLeft: '5%',
    },
    [theme.breakpoints.up(800)]: {
      marginLeft: '6%',
    },
    [theme.breakpoints.up(840)]: {
      marginLeft: '7%',
    },
    [theme.breakpoints.up(880)]: {
      marginLeft: '8%',
    },
    [theme.breakpoints.up(900)]: {
      marginLeft: '4%',
    },
    [theme.breakpoints.up(915)]: {
      marginLeft: '0',
    },
    [theme.breakpoints.up(1050)]: {
      marginLeft: '3%',
    },
    [theme.breakpoints.up(1200)]: {
      marginLeft: '5%',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '9%',
    },
    [theme.breakpoints.up('xs')]: {
      marginBottom: '100px',
    },
  },
  title: {
    color: '#FFF',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 700,
    letterSpacing: '-0.7px',
    fontSize: '35px',
    lineHeight: '124%',

    [theme.breakpoints.up('xs')]: {
      maxWidth: '400px',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '450px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '40px',
      maxWidth: '66%',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '45px',
      maxWidth: '800px',
    },
  },
  titleSecond: {
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 700,
    letterSpacing: '-0.7px',
    color: 'transparent',
    background: 'linear-gradient(94deg, #FFF 23.02%, #7C82F6 65.52%)',
    backgroundClip: 'text',
    fontSize: '35px',
    lineHeight: '124%',
    [theme.breakpoints.up('sm')]: {
      fontSize: '40px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '45px',
    },
  },
  titleHero: {
    color: '#53FFD6',
    fontWeight: 600,
    borderRadius: '138.126px',
    border: '2px solid #FFF',
    paddingLeft: '7px',
    paddingRight: '7px',
    paddingTop: '0px',
    paddingBottom: '5px',
    lineHeight: '1.1',
    display: 'inline-block',
    height: 'fit-content',
    fontSize: '30px',
    [theme.breakpoints.up('xs')]: {
      fontSize: '35px',
    },
  },
  phone: {
    position: 'relative',
    marginTop: '0',
    paddingTop: '25px',
    paddingLeft: '85px',
    height: '306px',
    width: '100%',
    backgroundImage: `url(${phone.src})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.up('xs')]: {
      marginTop: '-125px',
      backgroundPosition: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      width: '50%',
      right: 0,
      top: '40%',
    },
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      backgroundPosition: 'right',
      height: '400px',
      top: '29%',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '-50px',
      height: '420px',
      top: 0,
    },
  },
};
