export const localStorageKeys = {
  onboardingStage: 'nploy_onboarding_stage',
  jobCategories: 'nploy_job_categories',
  country_id: 'nploy_country',
  salary_min: 'nploy_salary_min',
  salary_max: 'nploy_salary_max',
  keywords: 'nploy_keywords',
  employment_levels: 'nploy_employment_levels',
  employment_types: 'nploy_employment_types',
  experience_years_min: 'nploy_experience_years_min',
  experience_years_max: 'nploy_experience_years_max',
  cities: 'nploy_cities',
  lang: 'nploy_lang',
  likedIds: 'nploy_liked_ids',
  dislikedIds: 'nploy_disliked_ids',
  externalDislikedIds: 'external_disliked_ids',
  externalLikedIds: 'external_liked_ids',
  work_models: 'work_models',
};

const persistInStorage = [localStorageKeys.lang];

export const getFromLocalStorage = (key: string) => {
  if (typeof window !== 'undefined') {
    if (
      window.localStorage.getItem(key) &&
      window.localStorage.getItem(key) !== 'undefined'
    ) {
      return JSON.parse(window.localStorage.getItem(key));
    }
  }
  return null;
};

export const setToLocalStorage = (
  key: string,
  value: Record<string, unknown> | string | number,
) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const clearLocalStorage = () => {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in localStorageKeys) {
      if (!persistInStorage.includes(localStorageKeys[key])) {
        window.localStorage.removeItem(localStorageKeys[key]);
      }
    }
  }
};

export const cookieKeys = localStorageKeys;

const persistInCookies = [cookieKeys.lang];

export const setCookie = (
  key: string,
  value: Record<string, unknown> | string | number,
  days = 30,
) => {
  if (typeof window !== 'undefined') {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = `expires=${date.toUTCString()}`;
    const stringValue = JSON.stringify(value);
    document.cookie = `${key}=${stringValue};${expires};path=/`;
  }
};

export const getCookie = (key) => {
  if (typeof window !== 'undefined') {
    const name = `${key}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');

    // eslint-disable-next-line no-restricted-syntax
    for (let cookie of cookieArray) {
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) === 0) {
        try {
          return JSON.parse(cookie.substring(name.length));
        } catch (e) {
          return null;
        }
      }
    }
  }
  return null;
};

export const clearCookies = () => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in cookieKeys) {
    if (!persistInCookies.includes(cookieKeys[key])) {
      document.cookie = `${cookieKeys[key]}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    }
  }
};

// Helper function to check if a cookie exists
export const checkCookie = (key) => getCookie(key) !== null;
