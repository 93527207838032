import { AnimatePresence, AnimatePresenceProps } from 'framer-motion';
import { FC, PropsWithChildren, ReactNode } from 'react';

type AnimatePresenceNoChildrenProps = Omit<AnimatePresenceProps, 'children'>;

interface NewAnimatePresenceProps extends AnimatePresenceNoChildrenProps {
  children: ReactNode;
}

export const AnimatedComponent: FC<
  PropsWithChildren<AnimatePresenceNoChildrenProps>
> = ({ children, ...props }) => {
  const NewAnimatePresence: FC<NewAnimatePresenceProps> = AnimatePresence;

  return <NewAnimatePresence {...props}>{children}</NewAnimatePresence>;
};
