import theme from 'theme/theme';

export const navLinksStyles = {
  navLinks: {
    display: 'flex',
    marginRight: theme.spacing(2),
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(2),
    justifyContent: 'flex-start',
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      maxWidth: 450,
    },
  },
  inDrawer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
};
