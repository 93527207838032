import { FC, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import useTranslate from 'utils/i18n/useTranslate';
import { HomeBenefitsCardOne } from 'components/home/home-benefits-card-one';
import { HomeBenefitsCardThree } from 'components/home/home-benefits-card-three';
import { HomeBenefitsCardTwo } from 'components/home/home-benefits-card-two';
import { AppLink, homeBenefitsStyles } from './home-benefits.styles';

const getMobileOperatingSystem = () => {
  if (typeof window === 'undefined') {
    return undefined;
  }

  const userAgent =
    window.navigator.userAgent ||
    window.navigator.vendor ||
    (window as any).opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return null;
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return 'iOS';
  }

  return null;
};

export const HomeBenefits: FC = () => {
  const { t } = useTranslate('home');
  const [userBrowser, setUserBrowser] =
    useState<ReturnType<typeof getMobileOperatingSystem>>(null);

  useEffect(() => {
    setUserBrowser(getMobileOperatingSystem());
  }, []);

  return (
    <Box component="section" sx={homeBenefitsStyles.wrap}>
      <Box
        sx={homeBenefitsStyles.phone}
        role="img"
        aria-label="nPloy mobile app interface displaying the logo"
      />
      {userBrowser && (
        <AppLink
          href={
            userBrowser === 'iOS'
              ? 'https://apps.apple.com/app/id1422387439'
              : 'market://details?id=com.nploy.mobileapp.new'
          }
          aria-label={t('marketing.downloadApp')}
        >
          {t('marketing.downloadApp')}
        </AppLink>
      )}
      <Box>
        <Typography variant="body2" sx={homeBenefitsStyles.subtitle}>
          {t('marketing.benefitsSubtitle')}
        </Typography>
      </Box>
      <Box sx={homeBenefitsStyles.cardWrapper}>
        <HomeBenefitsCardOne />
        <HomeBenefitsCardTwo />
        <HomeBenefitsCardThree />
      </Box>
    </Box>
  );
};
